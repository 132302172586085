body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.one-rem {
  font-size: 1rem;
}

.button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: white;
  color: black;
  font-family: sans-serif;

  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button:hover,
.button:focus {
  background: black;
  color: white;
}

.button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.button:active {
  transform: scale(0.99);
}

.button:disabled {
  opacity: 0.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div[id="root"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.forty {
  max-width: 40vw;
}

.centered-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wordlist {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style-type: none;
  margin-bottom: 3vh;
}

.input-box {
  margin-bottom: 3vh;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.entryfield {
  margin-right: 0.2vw;
  border-radius: 2px;
  border: none;
  padding: 1vw;
}

.cta {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cta-btn {
  flex: 1;
  max-width: calc(100% * 0.5 - 2px);
  border-radius: 2px;
}

@media screen and (max-width: 600px) {
  .forty {
    max-width: 80vw;
  }
  .start-game.button {
    font-size: 10vh;
    width: 100%;
    margin: 2vh 0;
    border-radius: 1vw;
  }
}

@media screen and (max-width: 300px) {
  .forty {
    max-width: 80vw;
  }
  .start-game {
    font-size: 10vh;
    width: 100%;
    margin: 2vh 0;
    border-radius: 1vw;
  }
}

.start-game {
  font-size: 10vh;
  width: 40vw;
  margin: 2vh 0;
  border-radius: 1vw;
}

.secondary {
  opacity: 0.5;
}

/***** Animation CSS Code *****/
.jitterbug {
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  min-width: 80vw;
  min-height: 80vh;
  margin-bottom: 35px;
  background-color: #608341;
  color: white;
  font-size: 20vh;
}

.jitterbug-1 {
  animation-name: jitter-1;
  color: black;
  background-color: orange;
}
.jitterbug-2 {
  animation-name: jitter-2;
  color: black;
  background-color: yellow;
}
.jitterbug-3 {
  background-color: #7f9a65;
  animation-name: jitter-3;
}

@keyframes jitter-1 {
  10% {
    translate: (-4px, -5px) scale(1.01, 1.01);
  }
  20% {
    transform: translate(4px, 3px) scale(0.99, 0.99);
  }
  30% {
    transform: translate(-6px, -7px) scale(1.01, 1.01);
  }
  40% {
    transform: translate(4px, 5px) scale(1, 1);
  }
  50% {
    transform: translate(-3px, -4px) scale(0.98, 0.98);
  }
  60% {
    transform: translate(2px, 5px) scale(1.02, 1.02);
  }
  70% {
    transform: translate(-4px, -6px) scale(1, 1);
  }
  80% {
    transform: translate(5px, 75px) scale(0.99, 0.99);
  }
  90% {
    transform: translate(-7px, -5px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(5px, 3px) scale(0.95, 0.95);
  }
}

@keyframes jitter-2 {
  10% {
    transform: translate(-3px, -4px) scale(1.01, 1.01);
  }
  20% {
    transform: translate(4px, 3px) scale(0.99, 0.99);
  }
  30% {
    transform: translate(-5px, -6px) scale(1.01, 1.01);
  }
  40% {
    transform: translate(3px, 4px) scale(1, 1);
  }
  50% {
    transform: translate(-2px, -3px) scale(0.98, 0.98);
  }
  60% {
    transform: translate(1px, 4px) scale(1.02, 1.02);
  }
  70% {
    transform: translate(-3px, -5px) scale(1, 1);
  }
  80% {
    transform: translate(4px, 6px) scale(0.99, 0.99);
  }
  90% {
    transform: translate(-6px, -4px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(4px, 2px) scale(0.95, 0.95);
  }
}

@keyframes jitter-3 {
  10% {
    transform: translate(-2px, -3px) scale(1.01, 1.01);
  }
  20% {
    transform: translate(3px, 2px) scale(0.99, 0.99);
  }
  30% {
    transform: translate(-4px, -5px) scale(1.01, 1.01);
  }
  40% {
    transform: translate(2px, 3px) scale(1, 1);
  }
  50% {
    transform: translate(-1px, -2px) scale(0.98, 0.98);
  }
  60% {
    transform: translate(0px, 3px) scale(1.02, 1.02);
  }
  70% {
    transform: translate(-2px, -4px) scale(1, 1);
  }
  80% {
    transform: translate(3px, 5px) scale(0.99, 0.99);
  }
  90% {
    transform: translate(-5px, -3px) scale(1.1, 1.1);
  }
  100% {
    transform: translate(3px, 1px) scale(0.95, 0.95);
  }
}
