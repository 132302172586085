/***** Base Layout Styles *****/
/* These styles are not needed for the animation to work!! Styles were simply added to this pen for display purposes. */
h1,
p {
  font-family: "Roboto", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  background-color: pink;
  background: linear-gradient(-200deg, pink, #bb7c7c, #fb7c7c);
}

.project-title--container,
.project-link--container {
  width: 100%;
  text-align: center;
}

.project-title,
.project-link {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.project-title {
  font-size: 18px;
  margin-top: 10px;
}

.project-link,
.project-link a {
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fa {
  font-size: 22px !important;
  padding: 0 5px;
  color: #fff;
}
