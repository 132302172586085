.wordlist-item {
  font-size: 1rem;
}

.x-button {
  background-color: transparent;
  border: none;
  color: darkred;
}

@media screen and (max-width: 600px) {
  .forty {
    max-width: 80vw;
  }
}
